import { IntlMoney } from '@/Components'
import { renderMarkRule } from 'react-datocms'

type CurrencyMarkChildren = {
  props: {
    children: string[]
  }
}

export const renderCurrencyMarkRule = renderMarkRule(
  'currency',
  ({ children, key }) => {
    if (children && 'props' in children) {
      const money = parseInt(
        (children as CurrencyMarkChildren).props.children[0].replace(/ /g, '')
      )
      return <IntlMoney key={key} value={money} maximumFractionDigits={0} />
    }

    return <span key={key}>{children}</span>
  }
)
