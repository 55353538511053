import {
  AnchorRouter,
  FynbosIcon,
  FynbosLogo,
  Icon,
  Prose,
  Router
} from '@/Components'
import { headingToSlug } from '@/Components/Content'
import { LearnV2Record, Maybe } from '@/Generated/dato-cms-graphql'
import { cn } from '@/Lib'
import { Link, usePage } from '@inertiajs/react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import { FC, ReactNode, useMemo, useRef } from 'react'
import { NavDrawer } from './NavDrawer'

const appUrl = import.meta.env.VITE_APP_URL || 'https://wealth.fynbos.app'

type LearnScaffoldProps = {
  children?: ReactNode
}

type Props = {
  nav: { data: { allLearnV2s: Maybe<Array<LearnV2Record>> } }
  content: { data: { learnV2: Maybe<LearnV2Record> } }
}

export const LearnLayout: FC<LearnScaffoldProps> = ({ children }) => {
  const { nav } = usePage<Props>().props

  const { url } = usePage()

  const defaultNavItems = useMemo(() => {
    const active = new URL(url, appUrl)
    const allNav = nav.data.allLearnV2s

    if (allNav == null) return []

    for (const i of allNav) {
      const index =
        i.children?.findIndex(
          (val) => `/learn/${val?.slug}` == active.pathname
        ) ?? -1

      if (index == -1) continue

      return [i.id]
    }
  }, [url, nav])

  const [previous, next] = useMemo(() => {
    const active = new URL(url, appUrl)
    const allNav = nav.data.allLearnV2s
    const group: Array<LearnV2Record | null> = [null, null]

    if (allNav == null) return group

    for (const i of allNav) {
      const index =
        i.children?.findIndex(
          (val) => `/learn/${val?.slug}` == active.pathname
        ) ?? -1

      if (index == -1) continue

      if (index != 0 && i.children) {
        group[0] = i.children[index - 1]
      }

      if (i.children && index + 1 < i.children.length) {
        group[1] = i.children[index + 1]
      }

      return group
    }

    return group
  }, [url, nav])

  return (
    <div className='relative inset-0 flex min-h-dvh w-full flex-col bg-app lg:flex-row'>
      <nav className='hidden h-full w-72 lg:fixed lg:flex lg:flex-col'>
        <Link href='/' className='py-7 pl-7'>
          <FynbosLogo />
        </Link>
        <ScrollArea.Root className='relative h-full w-full overflow-hidden'>
          <ScrollArea.Viewport className=' h-full w-full'>
            <AccordionPrimitive.Root
              defaultValue={defaultNavItems}
              type='multiple'
              className='flex max-w-72 flex-col gap-1 p-4'
            >
              {nav.data.allLearnV2s?.map((record) => (
                <AccordionPrimitive.Item
                  id={record.id}
                  key={record.id}
                  value={record.id}
                >
                  <AccordionPrimitive.Header>
                    <AccordionPrimitive.Trigger className='flex w-full items-center justify-between rounded-xl p-3 text-sm text-medium hover:bg-nav data-[state=open]:text-strong [&[data-state=open]>span]:rotate-180'>
                      {record.title}
                      <Icon className='transition-transform duration-200'>
                        expand_more
                      </Icon>
                    </AccordionPrimitive.Trigger>
                  </AccordionPrimitive.Header>
                  <AccordionPrimitive.Content className='flex flex-col gap-1 pl-4 data-[state=open]:py-1'>
                    {record.children?.map((section) => (
                      <NavItem href={section?.slug || ''} key={section?.id}>
                        {section?.title}
                      </NavItem>
                    ))}
                  </AccordionPrimitive.Content>
                </AccordionPrimitive.Item>
              ))}
            </AccordionPrimitive.Root>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className='flex touch-none select-none p-0.5 transition-colors duration-[160ms] ease-out hover:bg-slate-50 data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col'
            orientation='vertical'
          >
            <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-slate-300 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
        <div className='sticky bottom-0 flex p-4'>
          <Router
            href='/home'
            className='flex w-full items-center gap-2 rounded-xl p-3 text-sm text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
          >
            <FynbosIcon /> <span>Go to app</span>
          </Router>
        </div>
      </nav>
      <header className='sticky top-0 z-10 flex w-full border-b border-slate-200 bg-white/95 lg:hidden [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur'>
        <div className='mx-auto flex w-full items-center gap-x-4 p-4 md:max-w-4xl'>
          <NavDrawer>
            <AccordionPrimitive.Root
              type='multiple'
              className='flex flex-col gap-1'
            >
              {nav.data.allLearnV2s?.map((record) => (
                <AccordionPrimitive.Item key={record.id} value={record.id}>
                  <AccordionPrimitive.Header>
                    <AccordionPrimitive.Trigger className='flex w-full items-center justify-between rounded-xl p-3 text-sm text-medium hover:bg-nav data-[state=open]:text-strong [&[data-state=open]>span]:rotate-180'>
                      {record.title}
                      <Icon className='transition-transform duration-200'>
                        expand_more
                      </Icon>
                    </AccordionPrimitive.Trigger>
                  </AccordionPrimitive.Header>
                  <AccordionPrimitive.Content className='flex flex-col gap-1 pl-4 data-[state=open]:py-1'>
                    {record.children?.map((section) => (
                      <NavItem href={section?.slug || ''} key={section?.id}>
                        {section?.title}
                      </NavItem>
                    ))}
                  </AccordionPrimitive.Content>
                </AccordionPrimitive.Item>
              ))}
            </AccordionPrimitive.Root>
            <div className='fixed bottom-0 flex py-4'>
              <Router
                href='/home'
                className='flex w-full items-center gap-2 rounded-xl p-3 text-sm text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
              >
                <FynbosIcon /> <span>Go to app</span>
              </Router>
            </div>
          </NavDrawer>
          <Link href={'/'}>
            <FynbosLogo />
          </Link>
        </div>
      </header>
      <div className='flex w-full gap-6 rounded-xl bg-container-strong px-4 lg:my-4 lg:ml-72 lg:mr-4'>
        <main className='mx-auto flex gap-10 pb-40 pt-10'>
          <div className='flex w-full max-w-prose flex-col'>
            <Prose className='flex w-full flex-col'>{children}</Prose>
            <div className='mt-10 flex w-full justify-between gap-4'>
              {previous && previous.slug ? (
                <Router
                  href={previous.slug}
                  className='flex w-full flex-col gap-2 rounded-xl p-3 hover:bg-app'
                >
                  <div className='flex items-center gap-1 font-medium text-primary'>
                    <Icon>keyboard_arrow_left</Icon> Previous
                  </div>
                  <span>{previous.title}</span>
                </Router>
              ) : (
                <div className='w-full' />
              )}
              {next && next.slug && (
                <Router
                  href={next.slug}
                  className='flex w-full flex-col items-end gap-2 rounded-xl p-3 hover:bg-app'
                >
                  <div className='flex items-center gap-1 font-medium text-primary'>
                    Next <Icon>keyboard_arrow_right</Icon>
                  </div>
                  <span>{next.title}</span>
                </Router>
              )}
            </div>
          </div>
          <OnThisPage />
        </main>
      </div>
    </div>
  )
}

type OnThisPageItems = {
  heading: string
  slug: string
  level: number
}[]

const OnThisPage: FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { content } = usePage<Props>().props

  const onThisPage = useMemo<OnThisPageItems>(() => {
    const items = content.data.learnV2?.content?.value.document.children

    if (typeof items == 'undefined') return []

    const headings: OnThisPageItems = []
    for (const item of items) {
      if (item.type == 'heading' && item.level < 4) {
        headings.push({
          heading: item.children[0].value,
          slug: headingToSlug(item.children[0].value),
          level: item.level
        })
      }
    }
    return headings
  }, [content.data.learnV2?.content?.value.document.children])

  if (onThisPage.length == 0) return null

  return (
    <div className='sticky top-20 hidden h-max min-w-56 flex-col gap-1 text-sm md:flex'>
      <AnchorRouter href='#_top'>
        <span className='pb-2 font-medium text-strong'>On this page</span>
      </AnchorRouter>
      <div ref={ref} className='relative flex flex-col gap-1'>
        {onThisPage.map((page, index) => (
          <AnchorRouter
            href={`#${page.slug}`}
            key={`otp-${index}`}
            className={cn(
              'text-weak hover:text-strong',
              page.level == 3 && 'ml-3',
              page.level == 2 && 'mt-2'
            )}
          >
            {page.heading}
          </AnchorRouter>
        ))}
      </div>
    </div>
  )
}

OnThisPage.displayName = 'ListItem'

type NavItemProps = {
  children?: ReactNode
  className?: string
  href: string
}

const NavItem: FC<NavItemProps> = ({ children, href, className }) => {
  const { url } = usePage()

  const to = useMemo(() => {
    const active = new URL(url, appUrl)
    const toUrl = `/learn/${href}`
    return {
      href: toUrl,
      active: toUrl.toLowerCase() === active.pathname.toLowerCase()
    }
  }, [url, href])

  return (
    <Router
      href={to.href}
      className={cn(
        'relative w-full truncate rounded-xl p-3 text-sm text-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus',
        to.active ? 'bg-nav-active text-strong' : 'hover:bg-nav',
        className
      )}
    >
      {children}
    </Router>
  )
}

NavItem.displayName = 'ListItem'
